export default {
  status_active: "Active",
  status_cancel: "Canceled",
  status_terminated: "Ended",
  noData_table: "No data available",
  cancel_plan: "Cancel Plan",
  cancel_plan_text:
    "By canceling the plan, you will not receive the current period's reward and may be charged a cancel fee from the staking plan. We recommend staying until the end of the current period to earn rewards. Are you sure you want to cancel the plan?",
  back: "Back",
  activate_auto_renewal: "Activate Auto-Renewal",
  deactivate_auto_renewal: "Deactivate Auto-Renewal",
  active_auto_renewal_text:
    "With auto-renewal, at the end of the staking period, your staked assets will be transferred to the next period. Note that the staking reward for each period will be released at the end of that period and deposited into your wallet. The reward amount will not be transferred to the next staking period.",
  inactive_auto_renewal_text:
    "By canceling auto-renewal, at the end of the staking period, your staked assets and rewards will be released, and you will not be included in the next staking period.",
  activate: "Activate",
  deactivate: "Deactivate",
  staking_plans: "Staking Plans",
  success_request: "Your request was successfully registered.",
  unknown_mistake: "Unknown error",
  my_plans: "My Stakes",
  gain_reward: "Staking",
  gain_reward_text:
    "By participating in various Excoino staking, you can earn rewards by staking your crypto assets. Excoino has simplified the staking process to provide various crypto opportunities to its users.",
  plan_duration: "Plan Duration:",
  day: "Day",
  plan_status: "Plan Status:",
  exist: "Active",
  not_exist: "Inactive",
  approximate_active_reward: "Approximate Active Reward, APR (Annual)",
  rewards_earned: "Rewards Earned to Date",
  participation_rate: "Your Participation Rate",
  cancel_fee: "Cancel Fee",
  renewal_fee: "Auto-Renewal Fee",
  cancel: "Cancel",
  free: "Free",
  remain_duration: "Days Remaining / Total Days",
  start_date: "Joining Date",
  cancel_date: "Cancel Date",
  release_duration: "Release Waiting Period",
  cancel_release_duration: "Release Waiting Period After Cancel",
  auto_renewal: "AutoRenewal",
  date: "Date",
  reward_percent: "Reward %",
  earned_money: "Daily Earnings",
  reward_table: "Reward Table",
  yearly_active_apr: "Active APR (Annual Percentage Return)",
  min_max_apr: "Min/Max APR",
  remained_plan: "Remaining Plan",
  registration_time: "Registration Deadline",
  min_max_input: "Min/Max Input Amount",
  to: "to",
  registration_fee: "Registration Fee",
  cancelable: "Cancelable",
  has: "Yes",
  not_has: "No",
  reward: "Reward",
  daily: "Daily",
  weekly: "Weekly",
  monthly: "Monthly",
  yearly: "Yearly",
  balance: "Balance",
  increase_balance: "Increase Balance",
  stake_amount: "Stake Amount",
  insufficient_balance: "Insufficient Balance",
  insufficient_min_amount: "Less than allowed amount",
  insufficient_max_amount: "More than allowed amount",
  terms_1: "I accept the",
  terms_2: "terms and conditions",
  stake: "Stake",
  apr_reward: "APR or Reward",
  apr_reward_text:
    "Indicates the reward rate of an investment over one year. For example, if you invest 1000 tokens with an APR of 10%, you will have 1050 tokens after 6 months and 1100 tokens at the end of the year.",
  fixed_stake: "Fixed Annual Percentage Yield",
  fixed_stake_text:
    "By participating in fixed annual yield plans, you will receive a stable approximate rate throughout the period.",
  flexible_stake: "Variable Annual Percentage Yield",
  flexible_stake_text:
    "In these plans, Excoino guarantees a minimum reward and the amount may be higher based on network, market conditions, or plan. Rewards are calculated daily with the active daily rate.",
  earned_reward: "Rewards (to date)",
  remained_time: "Time Remain",
  hour: "Hour",
  minute: "Minute",
  view_detail: "View Details",
  fixed_apr: "Fixed APR",
  max_apr: "Max APR",
  active_apr: "Active APR",
  min_apr: "Min APR",
  registered_plan: "You are registered in this plan",
  min_stake_amount: "Minimum Stake Amount",
  show_more: "Show More",
  registration_expired: "Registration Closed",
  registration_not_started: "Registration Not Started",
  filled_capacity: "Capacity Filled",
  enter_plan: "Join Plan",
  login: "Login",
  cancel_reward_earned: "Reward Earned",
  released_amount: "Released Amount",
  max_capacity: "Total Capacity",
  renewal_table: "Renewal Records",
  renewal_date: "Renewal Date",
  renewal_status: "Status",
  instant: "Instant",
  stake_in_plan: "Stake in Plan",
  my_stakes: "My Stakes",
  plan_name: "Duration",
  plan_status_table: "Plan Status"
}
