export default {
    withdraw: "Withdraw",
    sendTo: "Send to:",
    excoinoWallet: "Excoino Wallet",
    excoinoTransfer: "Transfer to Excoino",
    otherWallets: "Other Wallets",
    crypto: "Cryptocurrency",
    noCryptos: "No cryptocurrencies available",
    selectCoin: "Please select a cryptocurrency",
    network: "Network",
    selectNetwork: "Please select a network",
    noNetwork: "No network available",
    address: "Address",
    addressList: "Address List",
    tag: "Tag/Memo",
    cryptoAmount: "Cryptocurrency Amount",
    availableBalance: "Available Balance",
    enterCryptoAmount: "Please enter the cryptocurrency amount",
    all: "All",
    fee: "Fee",
    netWithdrawalAmount: "Net Withdrawal Amount",
    pickup: "Withdraw",
    transfer: "Transfer",
    maxWithdrawCeil: "Daily Withdrawal Limit:",
    maxWithdrawCeilTip: "Daily Withdrawal Limit: The maximum amount that can be withdrawn from Excoino Market daily is {max}.",
    warning1: "Please ensure the accuracy of the destination address on the {network} network before withdrawing; any mistakes during withdrawal are irreversible, and the withdrawn amount cannot be refunded.",
    warning2: "The minimum withdrawal amount is {coin} {min}, and withdrawals below this amount will not be processed.",
    warning3: "For the security of your transfers, change the secure transfer password periodically.",
    warning4: "The transfer process will be completed and notified after expert approval.",
    callSupport: "You cannot request a withdrawal, please contact support!",
    smsVerification: "SMS Verification",
    confirmTransfer: "Confirm Transfer",
    smsCode: "SMS Verification Code",
    withdrawPss: "Secure Withdrawal Password",
    confirm: "Confirm",
    cancel: "Cancel",
    registerToGetCode: "To receive the confirmation code, register your mobile number in your Excoino user account.",
    enterAddress: "Please enter the address",
    invalidAddress: "Invalid address",
    enterAmount: "Please enter the cryptocurrency amount",
    maxWithdrawValidation: "The maximum approximate withdrawal amount is {coin} {maxAmount}",
    minWithdrawValidation: "The minimum approximate withdrawal amount is {coin} {minAmount}",
    insufficientBalance: "Insufficient balance",
    enterWithdrawPass: "Please enter the secure withdrawal password",
    getCode: "Get Code",
    enterTag: "Please enter the tag/memo",
    enterCode: "Please enter the SMS code",
    notAbleToTransfer: "Transferring the selected cryptocurrency to Excoino is not possible.",
    setWithdrawPass: "Please set the secure withdrawal password",
    title: "Title",
    enterTitle: "Please enter the title",
    invalidPhoneNumber: "Invalid phone number format. Please re-enter.",
    invalidCode: "Invalid verification code",
    invalidEmail: "Invalid email format, please re-enter",
    successSave: "Successfully saved",
    operation: "Operation",
    delete: "Delete",
    withdrawTime: "Withdrawal Time",
    amount: "Amount",
    netAmount: "Net Withdrawal Amount",
    trxHash: "Transaction Hash",
    status: "Status",
    refCode: "Reference Code",
    withdrawType: "Withdrawal Type",
    transferToExcoino: "Transfer from Market to Excoino",
    backToWithdrawPage: "Back to Withdraw Page",
    submit: "Submit",
    addressVerificationCode: "Address Verification Code",
    save: "Save",
    approvedTransfer: "Are you sure you want to transfer?"
  };
  