export default {
    withdraw: "برداشت",
    sendTo: "ارسال به:",
    excoinoWallet: "کیف پول اکسکوینو",
    excoinoTransfer: "انتقال به اکسکوینو",
    otherWallets: "سایر کیف پول‌ها",
    crypto: "رمزارز",
    noCryptos: "رمزارزی موجود نیست",
    selectCoin: "لطفا یک رمزارز انتخاب کنید",
    network: "شبکه",
    selectNetwork: "لطفا یک شبکه را انتخاب کنید",
    noNetwork: "شبکه‌ای موجود نیست",
    address: "آدرس",
    addressList: "فهرست آدرس ها",
    tag: "تگ/ممو",
    cryptoAmount: "مقدار رمزارز",
    availableBalance: "موجودی قابل انتقال",
    enterCryptoAmount: "لطفا مقدار رمزارز را وارد کنید",
    all: "همه",
    fee: "کارمزد",
    netWithdrawalAmount: "مقدار خالص برداشت",
    pickup: "برداشت",
    transfer: "انتقال",
    maxWithdrawCeil: "سقف برداشت روزانه:",
    maxWithdrawCeilTip: "سقف برداشت روزانه: حداکثر مقدار قابل برداشت از اکسکوینومارکت روزانه معادل {max} است.",
    warning1: "لطفا قبل از برداشت، کاملا از صحت آدرس مقصد در شبکه {network} اطمینان حاصل کنید؛ هر اشتباهی هنگام برداشت، غیر قابل جبران است و امکان بازگشت مبلغ برداشته شده وجود ندارد.",
    warning2: "حداقل مقدار برداشت {coin} {min} است و برداشت کمتر از این مقدار محاسبه نمی‌شود.",
    warning3: "برای حفظ امنیت انتقال‌های خود، رمز انتقال امن را پس از مدتی تغییر دهید.",
    warning4: "فرآیند انتقال، پس از تایید کارشناسان، تکمیل و اطلاع رسانی می‌شود.",
    callSupport: "شما قادر به ثبت درخواست برداشت نیستید، لطفا با پشتیبانی تماس بگیرید!",
    smsVerification: "تایید پیامکی",
    confirmTransfer: "تایید انتقال",
    smsCode: "کد تایید پیامکی",
    withdrawPss: "رمز برداشت امن",
    confirm: "تایید",
    cancel: "لغو",
    registerToGetCode: "جهت دریافت کد تائید شماره موبایل خود را در حساب کاربر اکسکوینو ثبت کنید.",
    enterAddress: "لطفا آدرس را وارد کنید",
    invalidAddress: "آدرس معتبر نیست",
    enterAmount: "لطفا مقدار رمزارز را وارد کنید",
    maxWithdrawValidation: "حداکثر مقدار تقریبی برداشت {coin} {maxAmount} می‌باشد",
    minWithdrawValidation: "حداقل مقدار تقریبی برداشت {coin} {minAmount} می‌باشد",
    insufficientBalance: "موجودی کافی نیست",
    enterWithdrawPass: "لطفا رمز برداشت امن را وارد کنید",
    getCode: "دریافت کد",
    enterTag: "لطفا تگ/ممو را وارد کنید",
    enterCode: "لطفا کد پیامک شده را وارد کنید",
    notAbleToTransfer: "انتقال رمزارز مورد نظر به اکسکوینو امکانپذیر نیست.",
    setWithdrawPass: " لطفا رمز برداشت امن را تنظیم نمایید",
    title: "عنوان",
    enterTitle: "لطفا عنوان را وارد کنید",
    invalidPhoneNumber: "فرمت تلفن اشتباه است. مجددا وارد نمایید.",
    invalidCode: "کد تایید اشتباه است",
    invalidEmail: "خطای فرمت ایمیل ، دوباره وارد شوید",
    successSave: "با موفقیت دخیره شد",
    operation: "عملیات",
    delete: "حذف",
    withdrawTime: "زمان برداشت",
    amount: "مقدار",
    netAmount: "مقدار خالص برداشت",
    trxHash: "هش تراکنش",
    status: "وضعیت",
    refCode: "کد پیگیری",
    withdrawType: "نوع برداشت",
    transferToExcoino: "انتقال از مارکت به اکسکوینو",
    backToWithdrawPage: "بازگشت به صفحه برداشت",
    submit: "ثبت",
    addressVerificationCode: "کد تایید آدرس",
    save: "ذخیره",
    approvedTransfer:"آیا از انتقال اطمینان دارید؟"
}