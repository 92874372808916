import {mapGetters, mapActions} from "vuex";
import TimeBar from "@/components/TimeBar/index";
import HeaderNavMobile from "@/components/App/HeaderNavMobile/index";
import TermsOfUseModal from "@/components/TermsOfUseModal/index";
import AppHeader from "@/components/App/AppHeader/index";
import TransitionPage from "@/components/UI/TransitionPage/index";
import BackToTop from "@/components/App/BackToTop";

export default {
    name: "app",
    components: {
        TimeBar,
        HeaderNavMobile,
        TermsOfUseModal,
        AppHeader,
        TransitionPage,
        BackToTop,
    },
    provide() {
        return {
            reload: this.reload,
        };
    },
    data() {
        return {
            baseUrl: process.env.VUE_APP_EXCOINO_PANEL_URL,
            ShowTermsOfUseModal: false,
            isRouterAlive: true,
            pageView: "page-view",
            content: "",
            navDrawerModal: false,
        };
    },
    watch: {
        activeNav: function () {
            switch (this.activeNav) {
                case "nav-dashboard":
                    window.document.title = "Dashboard - " + this.$t('common.pagesTitle');
                    break;
                case "nav-markets":
                    window.document.title = "Markets - " + this.$t('common.pagesTitle');
                    break;
                case "nav-exchange":
                    window.document.title =
                        "Exchange - " + this.$t('common.pagesTitle');
                    break;
                case "nav-profile":
                    window.document.title =
                        "Profile - " + this.$t('common.pagesTitle');
                    break;
                // case "nav-deposit":
                //     window.document.title =
                //         "Deposit - " + this.$t('common.pagesTitle');
                //     break;
                // case "nav-withdraw":
                //     window.document.title =
                //         "Withdraw - " + this.$t('common.pagesTitle');
                //     break;
                case "nav-transfer":
                    window.document.title =
                        "Transfer - " + this.$t('common.pagesTitle');
                    break;
                case "nav-assets":
                    window.document.title =
                        "Assets - " + this.$t('common.pagesTitle');
                    break;
                case "nav-transactions":
                    window.document.title =
                        "Transactions - " + this.$t('common.pagesTitle');
                    break;
                case "nav-orders":
                    window.document.title =
                        "Orders - " + this.$t('common.pagesTitle');
                    break;
                case "nav-guide":
                    window.document.title =
                        "Guide - " + this.$t('common.pagesTitle');
                    break;
                case "nav-terms-of-use":
                    window.document.title =
                        "Terms of use - " + this.$t('common.pagesTitle');
                    break;
                default:
                    window.document.title = this.$t('common.pagesTitle');
                    break;
            }
        },
        $route() {
            this.pageView = "page-view";
        },
        exchangeSkin() {
        },
    },
    computed: {
        ...mapGetters({
            getActiveNav: "global/GET_ACTIVE_NAV",
            getLoginStatus: "global/IS_LOGIN",
            getMember: "global/MEMBER",
            getLang: "global/GET_LANG",
            getExchangeSkin: "global/GET_EXCHANGE_SKIN",
            getModalStatus: "global/GET_TERMS_OF_USE_MODAL_STATUS",
            getWithdrawStatus: "global/GET_WITHDRAW_STATUS",
            getDepositStatus: "global/GET_DEPOSIT_STATUS",
        }),
        withdrawStatus() {
            return this.getWithdrawStatus;
        },
        depositStatus() {
            return this.getDepositStatus;
        },
        showModal() {
            return this.getModalStatus;
        },
        activeNav() {
            return this.getActiveNav;
        },
        isLogin() {
            return this.getLoginStatus;
        },
        member() {
            return this.getMember;
        },
        languageValue() {
            var curlang = this.getLang;
            if (curlang == "Persian") this.$i18n.locale = "fa";
            return curlang;
        },
        lang() {
            return this.getLang;
        },
        exchangeSkin() {
            return this.getExchangeSkin;
        },
    },
    created() {
        this.getPairCoinScalesList();
        this.initialize();
        // hidden loading layer
        let initLoading = document.getElementById("initLoading");
        if (initLoading != null) {
            document.body.removeChild(initLoading);
        }
    },
    methods: {
        ...mapActions({
            setMember: "global/SET_MEMBER",
            setNavigation: "global/NAVIGATE",
            recoveryMember: "global/RECOVERY_MEMBER",
            initLang: "global/INIT_LANG",
            initLoginTimes: "global/INIT_LOGIN_TIMES",
            setDepositStatus: "global/SET_DEPOSIT_STATUS",
            setWithdrawStatus: "global/SET_WITHDRAW_STATUS",
            setLang: "global/SET_LANG",
            setExchangeSkin: "global/SET_SKIN",
            setTermsOfUseModalStatus: "global/SET_TERMS_OF_USE_MODAL_STATUS",
            setPairCoinScales: "market/SET_PAIR_COIN_SCALES",
            logoutUser: "userCenter/LOGOUT_SERVICE",
            getPairCoinScales: "market/GET_PAIR_COIN_SCALES_SERVICE",
            loginUser: "userCenter/LOGIN_SERVICE",
        }),
        reload() {
            this.isRouterAlive = false;
            this.$nextTick(function () {
                this.isRouterAlive = true;
            });
        },
        getPairCoinScalesList() {
            this.getPairCoinScales().then((response) => {
                this.setPairCoinScales(response.data.data);
            });
        },
        isMobile() {
            let flag = navigator.userAgent.match(
                /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
            );
            return flag;
        },
        toggleMenu() {
            this.navDrawerModal = !this.navDrawerModal;
        },
        strpo(str) {
            str = str.split("@")[0];
            if (str.length > 15) {
                str = str.slice(0, 15) + "···";
            } else {
                str = str;
            }
            return str;
        },
        initialize() {
            // this.setNavigation("nav-markets");
            this.recoveryMember();
            this.initLang();
            this.initLoginTimes();
        },
        logout() {
            this.logoutUser().then((response) => {
                if (response.data.code === 0) {
                    this.$toast.success(response.data.message);
                    this.setMember(null);
                    this.$cookies.remove("accessToken", null, "excoino.com");
                    this.$cookies.remove("user", null, "excoino.com");
                    this.$cookies.remove("userInfo", null, "excoino.com");
                    localStorage.clear();
                    setTimeout(() => {
                        window.location = this.baseUrl;
                    }, 1500);
                }
            }).catch(err => {
                this.$toast.error(err.message);
            })
        },
        confirmTermsOfUse() {
            this.setTermsOfUseModalStatus(false);
            this.loginUser().then((response) => {
                let result = response.data.data;
                this.setMember(result);
                this.setTermsOfUseModalStatus(false);
                window.location.reload();
            }).catch(err => {
                this.$toast.error(err.message);
                window.location = this.baseUrl;
            })
        },
        denyTermsOfUse() {
            // Do nothing!
            window.location = this.baseUrl;
        },
        changelanguage(name) {
            if (name == "en") {
                this.setLang("Farsi");
                this.$i18n.locale = "fa";
                this.reload();
            }
        },
    },
};
